@keyframes lightSpeedOutLeft {
  from {
    opacity: 1;
  }

  to {
    transform: translate3d(-100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
}

.lightSpeedOutLeft {
  animation-name: lightSpeedOutLeft;
  animation-timing-function: ease-in;
}
