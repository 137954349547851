@keyframes rotateInDownRight {
  from {
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateInDownRight {
  animation-name: rotateInDownRight;
  transform-origin: right bottom;
}
