@keyframes lightSpeedOutRight {
  from {
    opacity: 1;
  }

  to {
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

.lightSpeedOutRight {
  animation-name: lightSpeedOutRight;
  animation-timing-function: ease-in;
}
