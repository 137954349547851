@keyframes rotateInUpRight {
  from {
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateInUpRight {
  animation-name: rotateInUpRight;
  transform-origin: right bottom;
}
